import * as CONSTANTS from './layout.constants'

export const setHasFloatingCta = hasCta => ({
    type: CONSTANTS.SET_HAS_FLOATING_CTA,
    payload: hasCta,
})

export const setConfig = config => dispatch => {
    dispatch({
        type: CONSTANTS.SET_TEXT_COLOR_VARIANT,
        payload: config.textColorVariant,
    })

    dispatch({
        type: CONSTANTS.SET_CLASS_NAME,
        payload: config.className,
    })

    dispatch({
        type: CONSTANTS.SET_LOGO_VARIANT,
        payload: config.logoVariant,
    })

    dispatch({
        type: CONSTANTS.SET_LOGO_CLASSNAME,
        payload: config.logoClassName,
    })

    dispatch({
        type: CONSTANTS.SET_SHOW_LOGOUT,
        payload: config.showLogout,
    })

    dispatch({
        type: CONSTANTS.SET_HIDE_ON_MOBILE,
        payload: config.hideOnMobile,
    })

    dispatch({
        type: CONSTANTS.SET_ALWAYS_TOP,
        payload: config.alwaysTop,
    })

    dispatch({
        type: CONSTANTS.SET_HIDE_NAVIGATION,
        payload: config.hideNavigation,
    })

    dispatch({
        type: CONSTANTS.SET_SHOW_USER_NAME,
        payload: config.showUserName,
    })

    dispatch({
        type: CONSTANTS.SET_FOOTER_TYPE,
        payload: config.footerType,
    })

    dispatch({
        type: CONSTANTS.SET_HIDE_BANNER,
        payload: config.hideBanner,
    })
}
