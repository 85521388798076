import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import cn from 'classnames'
import {
    DrawerButton,
    Header,
    HeaderLogo,
    Icon,
    MobileDrawer,
    MobileDrawerItem,
    MobileNavigation,
    Navigation,
    NavigationItem,
} from '@gymondo/frontend-core/components'
import { LANGUAGES, LOCAL_STORAGE_KEYS } from '@gymondo/frontend-core/enums'
import logoBlack from '@gymondo/frontend-core/svg/logo-black.svg'
import { SmartBanner } from '@gymondo/frontend-core/page-sections/index'
import Cookies from 'js-cookie'
import { Tracker } from '@gymondo/frontend-core/utils'
import logoPrideMonth from './logo/pride-month-logo.svg'
import useIsClient from '../../hook/use-is-client/use-is-client.hook'
import { LinkLocalized } from '..'

import style from './page-header.module.scss'
import FreeWeekButton from './trial-cta-button'
import LoginButton from './login-button'
import CookieBannerContent from './cookie-banner-content'
import CookieBannerMobileContent from './cookie-banner-mobile-content'
import LoginModal from './login-modal'
import CampaignBanner from './campaign-banner/campaign-banner'
import { pageHeader as service } from '../../service'
import { fetch } from '../../service/user'
import { isRegionDACH, isRegionGermany } from '../../util/regions.util'

const influencerSourceCookieName = 'influencer.source'

const TEXT_COLOR_VARIANT_CLASSNAME_MAP = {
    black: 'top-header--black',
    white: 'top-header--white',
}

const LOGO_VARIANTS = {
    DEFAULT: 'default',
    CI: 'ci',
    HIDDEN: 'hidden',
    PRIDE: 'pride',
}

const ciLogo = <Icon data={logoBlack} className="gym-logo__svg--ci" />
const prideLogo = <Icon data={logoPrideMonth} className="gym-logo__svg--ci" />

export const PageHeader = ({
    textColorVariant,
    hideOnMobile,
    alwaysTop,
    logoVariant,
    className,
    hideNavigation,
    hideBanner,
    loginModalOpen,
    openLoginModal,
    closeLoginModal,
    showLogout,
    showUserName,
    isLoggedIn,
    fetchUser,
    logoClassName,
    loginModalForceOpen,
}) => {
    const loginButtonBordered = textColorVariant === 'black'
    const linkClassName = 'top-header__link'
    const _ciLogo = logoVariant === LOGO_VARIANTS.CI && ciLogo
    const _prideLogo = logoVariant === LOGO_VARIANTS.PRIDE && prideLogo
    const isClient = useIsClient()
    const intl = useIntl()

    useEffect(() => {
        if (showLogout && isLoggedIn) {
            fetchUser()
        }
    }, [fetchUser, showLogout, isLoggedIn])

    const { isEU } = useSelector((state) => state.cookieConsent)

    const isGermanLangauge = intl.locale === LANGUAGES.GERMAN

    return (
        <>
            {loginModalOpen && (
                <LoginModal
                    forceOpen={loginModalForceOpen}
                    onClose={() => closeLoginModal()}
                />
            )}
            {isClient && !Cookies.get(influencerSourceCookieName) && (
                <SmartBanner
                    title="smart-banner.title"
                    shortTitle="smart-banner.shortTitle"
                    subtext="smart-banner.subtext"
                    cta="smart-banner.cta"
                    link={
                        process.env.GATSBY_DYNAMIC_LINK +
                        window.location.origin +
                        window.location.pathname
                    }
                />
            )}
            <Header
                className={cn(
                    TEXT_COLOR_VARIANT_CLASSNAME_MAP[textColorVariant],
                    hideOnMobile && 'hidden-xs',
                    className
                )}
                alwaysTop={alwaysTop}
                cookieText={CookieBannerContent}
                cookieMobileText={CookieBannerMobileContent}
                cookieBannerLocalStorageKey={LOCAL_STORAGE_KEYS.CONSENT}
                Banner={!hideBanner ? CampaignBanner : undefined}
                hideCookieBanner={isEU}
            >
                <LinkLocalized to="home" title="header.home">
                    <HeaderLogo
                        className={logoClassName}
                        initialComponent={_ciLogo || _prideLogo || undefined}
                    />
                </LinkLocalized>
                {hideNavigation && showLogout && (
                    <>
                        <Navigation>
                            <NavigationItem>
                                <LoginButton
                                    showLogout
                                    showUserName={showUserName}
                                    bordered={loginButtonBordered}
                                    onClick={() => openLoginModal()}
                                />
                            </NavigationItem>
                        </Navigation>
                        <MobileNavigation>
                            <NavigationItem mobile>
                                <LoginButton
                                    showLogout
                                    showUserName={showUserName}
                                    bordered={loginButtonBordered}
                                    onClick={() => openLoginModal()}
                                />
                            </NavigationItem>
                        </MobileNavigation>
                    </>
                )}
                {!hideNavigation && (
                    <>
                        <Navigation>
                            <NavigationItem>
                                <LinkLocalized
                                    to="checkout"
                                    title="header.price"
                                    activeClassName={style.active}
                                    data-evt={Tracker.EVENT_TYPES.CLICK}
                                    data-category="global"
                                    data-action="click_price_cta"
                                    data-label="register_header"
                                >
                                    <FormattedMessage id="header.price" />
                                </LinkLocalized>
                            </NavigationItem>
                            <NavigationItem>
                                <LinkLocalized
                                    to="success"
                                    title="header.success"
                                    activeClassName={style.active}
                                >
                                    <FormattedMessage id="header.success" />
                                </LinkLocalized>
                            </NavigationItem>
                            <NavigationItem>
                                <LinkLocalized
                                    to="how-it-works"
                                    title="header.how-it-works"
                                    activeClassName={style.active}
                                >
                                    <FormattedMessage id="header.how-it-works" />
                                </LinkLocalized>
                            </NavigationItem>
                            {isRegionGermany && isGermanLangauge && (
                                <NavigationItem>
                                    <a
                                        href={
                                            process.env
                                                .GATSBY_PREVENTION_HOMEPAGE
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        data-evt={Tracker.EVENT_TYPES.CLICK}
                                        data-category="global"
                                        data-action="click_prevention_link"
                                    >
                                        <FormattedMessage id="header.health-insurance" />
                                    </a>
                                </NavigationItem>
                            )}
                            {isRegionDACH && (
                                <NavigationItem>
                                    <a
                                        href={intl.formatMessage({
                                            id: 'home.gymondo-shop-url',
                                        })}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FormattedMessage id="header.gymondo-shop-txt" />
                                    </a>
                                </NavigationItem>
                            )}
                            <NavigationItem>
                                <FreeWeekButton />
                            </NavigationItem>
                            <NavigationItem initial>
                                <LoginButton
                                    showUserName={showUserName}
                                    bordered={loginButtonBordered}
                                    onClick={() => openLoginModal()}
                                />
                            </NavigationItem>
                            <NavigationItem fixed>
                                <LoginButton
                                    showUserName={showUserName}
                                    bordered
                                    onClick={() => openLoginModal()}
                                />
                            </NavigationItem>
                        </Navigation>
                        <MobileNavigation>
                            <NavigationItem mobile>
                                <FreeWeekButton />
                            </NavigationItem>
                            <NavigationItem fixed mobile>
                                <LoginButton
                                    bordered
                                    onClick={() => openLoginModal()}
                                />
                            </NavigationItem>
                            <NavigationItem initial mobile>
                                <LoginButton
                                    bordered={loginButtonBordered}
                                    onClick={() => openLoginModal()}
                                />
                            </NavigationItem>
                            <NavigationItem mobile>
                                <DrawerButton variant={textColorVariant} />
                            </NavigationItem>
                        </MobileNavigation>
                        <MobileDrawer>
                            <MobileDrawerItem>
                                <LinkLocalized to="home" title="header.home">
                                    <HeaderLogo mobile />
                                </LinkLocalized>
                                <div className={style.drawerLoginButton}>
                                    <LoginButton
                                        showUserName={showUserName}
                                        bordered
                                        onClick={() => openLoginModal()}
                                    />
                                </div>
                                <DrawerButton isOpen />
                            </MobileDrawerItem>
                            <MobileDrawerItem>
                                <LinkLocalized
                                    to="home"
                                    className={linkClassName}
                                    activeClassName={style.active}
                                    title="header.home"
                                >
                                    <FormattedMessage id="header.home" />
                                </LinkLocalized>
                            </MobileDrawerItem>
                            <MobileDrawerItem>
                                <LinkLocalized
                                    to="checkout"
                                    className={linkClassName}
                                    activeClassName={style.active}
                                    title="header.price"
                                    data-evt={Tracker.EVENT_TYPES.CLICK}
                                    data-category="global"
                                    data-action="click_price_cta"
                                    data-label="register_header"
                                >
                                    <FormattedMessage id="header.price" />
                                </LinkLocalized>
                            </MobileDrawerItem>
                            <MobileDrawerItem>
                                <LinkLocalized
                                    to="success"
                                    className={linkClassName}
                                    activeClassName={style.active}
                                    title="header.success"
                                >
                                    <FormattedMessage id="header.success" />
                                </LinkLocalized>
                            </MobileDrawerItem>
                            <MobileDrawerItem>
                                <LinkLocalized
                                    to="how-it-works"
                                    className={linkClassName}
                                    activeClassName={style.active}
                                    title="header.how-it-works"
                                >
                                    <FormattedMessage id="header.how-it-works" />
                                </LinkLocalized>
                            </MobileDrawerItem>
                            {isRegionGermany && isGermanLangauge && (
                                <MobileDrawerItem>
                                    <a
                                        href={
                                            process.env
                                                .GATSBY_PREVENTION_HOMEPAGE
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={linkClassName}
                                        data-evt={Tracker.EVENT_TYPES.CLICK}
                                        data-category="global"
                                        data-action="click_prevention_link"
                                    >
                                        <FormattedMessage id="header.health-insurance" />
                                    </a>
                                </MobileDrawerItem>
                            )}
                            {isRegionDACH && (
                                <MobileDrawerItem>
                                    <a
                                        href={intl.formatMessage({
                                            id: 'home.gymondo-shop-url',
                                        })}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={linkClassName}
                                    >
                                        <FormattedMessage id="header.gymondo-shop-txt" />
                                    </a>
                                </MobileDrawerItem>
                            )}
                            <MobileDrawerItem>
                                <FreeWeekButton />
                            </MobileDrawerItem>
                        </MobileDrawer>
                    </>
                )}
            </Header>
        </>
    )
}

PageHeader.propTypes = {
    textColorVariant: PropTypes.oneOf(['white', 'black']),
    hideOnMobile: PropTypes.bool,
    alwaysTop: PropTypes.bool,
    logoVariant: PropTypes.oneOf(['ci', 'default', 'white']),
    className: PropTypes.string,
    hideNavigation: PropTypes.bool,
    hideBanner: PropTypes.bool,
    loginModalOpen: PropTypes.bool.isRequired,
    openLoginModal: PropTypes.func.isRequired,
    closeLoginModal: PropTypes.func.isRequired,
    showLogout: PropTypes.bool,
    showUserName: PropTypes.bool,
    isLoggedIn: PropTypes.bool.isRequired,
    fetchUser: PropTypes.func.isRequired,
    logoClassName: PropTypes.string,
    loginModalForceOpen: PropTypes.bool,
}

PageHeader.defaultProps = {
    textColorVariant: 'white',
    hideOnMobile: false,
    hideBanner: false,
    alwaysTop: false,
    logoVariant: LOGO_VARIANTS.DEFAULT,
    className: '',
    hideNavigation: false,
    showLogout: false,
    showUserName: false,
    logoClassName: null,
    loginModalForceOpen: false,
}

const mapStateToProps = ({ pageHeader, user }) => ({
    ...pageHeader,
    isLoggedIn: user.isLoggedIn,
})

export default connect(mapStateToProps, {
    openLoginModal: service.openLoginModal,
    closeLoginModal: service.closeLoginModal,
    fetchUser: fetch,
})(PageHeader)
