import { LANGUAGES } from '@gymondo/frontend-core/enums'
import { Tracker } from '@gymondo/frontend-core/utils'

import Facebook from '@gymondo/frontend-core/svg/soc-facebook.svg'
import Instagram from '@gymondo/frontend-core/svg/soc-instagram.svg'
import Youtube from '@gymondo/frontend-core/svg/soc-youtube.svg'

const copyright = () => new Date().getFullYear()

export const social = [
    { url: 'footer.instagram.link', svg: Instagram, title: 'Instagram' },
    { url: 'footer.facebook.link', svg: Facebook, title: 'Facebook' },
    { url: 'footer.youtube.link', svg: Youtube, title: 'Youtube' },
]

export const listGroups = {
    privacy: [
        {
            className: 'footer-list--inline',
            isExpanded: true,
            title: 'footer.copyright',
            titleValues: {
                year: copyright(),
            },
            links: [
                {
                    text: 'footer.terms',
                    url: 'term-and-condition',
                    description: 'footer.terms',
                },
                {
                    text: 'footer.imprint',
                    url: 'imprint',
                    description: 'footer.imprint',
                    className: 'footer-list__item--border',
                },
                {
                    text: 'footer.privacy',
                    url: 'privacy',
                    description: 'footer.privacy',
                },
            ],
            // additionalComponent: 'icons'
            additionalComponent: 'language',
        },
    ],
    facts: [
        {
            title: 'footer.more',
            links: [
                {
                    text: 'footer.price',
                    url: 'checkout',
                    description: 'footer.price',
                    tracking: {
                        'data-evt': Tracker.EVENT_TYPES.CLICK,
                        'data-category': 'global',
                        'data-action': 'click_price_cta',
                        'data-label': 'register_footer',
                    },
                },
                {
                    text: 'footer.how-it-works',
                    url: 'how-it-works',
                    description: 'footer.how-it-works',
                },
                {
                    text: 'footer.success',
                    url: 'success',
                    description: 'footer.success',
                },
            ],
        },
        {
            title: 'footer.service',
            links: [
                {
                    text: 'footer.community',
                    url: 'community',
                    description: 'footer.community',
                    view: 'facebook',
                },
                {
                    text: 'footer.support',
                    url: 'footer.support.link',
                    description: 'footer.support',
                    isExternal: true,
                },
                {
                    text: 'footer.cards',
                    url: 'footer.cards.link',
                    description: 'footer.cards',
                    isExternal: true,
                    allowedLanguages: [LANGUAGES.GERMAN],
                },
                {
                    text: 'footer.gymondo-shop-txt',
                    url: 'home.gymondo-shop-url',
                    description: 'footer.gymondo-shop-txt',
                    isExternal: true,
                    allowedRegions: ['DE', 'AT', 'CH'],
                },
            ],
        },
        {
            title: 'footer.facts',
            links: [
                {
                    text: 'footer.magazine',
                    url: 'footer.magazine.link',
                    description: 'footer.magazine',
                    isExternal: true,
                },
                {
                    text: 'footer.equipment',
                    url: 'footer.equipment.link',
                    description: 'footer.equipment',
                    isExternal: true,
                },
                {
                    text: 'footer.challenges',
                    url: 'footer.challenges.link',
                    description: 'footer.challenges',
                    isExternal: true,
                },
            ],
        },
        {
            title: 'footer.company',
            links: [
                {
                    text: 'footer.jobs',
                    url: 'footer.jobs.link',
                    description: 'footer.jobs',
                    isExternal: true,
                },
                {
                    text: 'footer.press',
                    url: 'footer.press.link',
                    description: 'footer.press',
                    isExternal: true,
                },
                {
                    text: 'footer.influencer',
                    url: 'footer.influencer.link',
                    description: 'footer.influencer',
                    isExternal: true,
                },
                {
                    text: 'footer.corporate',
                    url: 'footer.corporate.link',
                    description: 'footer.corporate',
                    isExternal: true,
                },
            ],
        },
    ],
}

export const note = {
    strong: 'footer.note.strong',
    text: 'footer.note',
}
