import jsCookie from 'js-cookie'
import { COOKIE_NAMES } from '@gymondo/frontend-core/enums'

export const isAllowedRegions = (regions) => {
    const region = jsCookie.get(COOKIE_NAMES.USER_REGION)

    return regions.includes(region)
}

export const isRegionDACH = isAllowedRegions(['DE', 'AT', 'CH'])

export const isRegionGermany = isAllowedRegions(['DE'])
