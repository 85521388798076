import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import cn from 'classnames'
import { connect } from 'react-redux'
import { mediaMaxSm } from '@gymondo/frontend-core/style-vars'
import { useMediaQuery } from '@gymondo/frontend-core/hooks'
import {
    Footer,
    FooterListGroup,
    FooterList,
    FooterListItem,
    FooterLogo,
    FooterSection,
    SocialIcon,
} from '@gymondo/frontend-core/components'

import { LinkLocalized } from '..'

import { social, listGroups, note } from './config/footer.config'
import LanguageSwitch from './component/language-switch/language-switch'

import style from './page-footer.module.scss'
import { isAllowedRegions } from '../../util/regions.util'

const _getSocialWithHref = (item, intl) => ({
    ...item,
    key: item.title,
    href: intl.formatMessage({ id: item.url }),
})

const renderLogo = (intl) => (
    <FooterSection>
        <div className="footer-header">
            <LinkLocalized to="home" title="footer.home">
                <FooterLogo />
            </LinkLocalized>
            <SocialIcon
                icons={social.map((item) => _getSocialWithHref(item, intl))}
            />
        </div>
    </FooterSection>
)

const renderNote = () => (
    <FooterSection>
        <p className="footer-note">
            <span className="footer-note--strong">
                <FormattedMessage id={note.strong} />
            </span>
            <FormattedMessage id={note.text} />
        </p>
    </FooterSection>
)

const renderListTitle = (list) => (
    <FooterListItem isTitle>
        <FormattedMessage values={list.titleValues} id={list.title} />
    </FooterListItem>
)

const renderListLinks = (intl, list) => {
    const filteredList = list.links.filter((item) => {
        const allowedLanguages =
            !item.allowedLanguages ||
            item.allowedLanguages.includes(intl.locale)
        const allowedRegions =
            !item.allowedRegions || isAllowedRegions(item.allowedRegions)

        return allowedLanguages && allowedRegions
    })

    return filteredList.map((item) => (
        <FooterListItem className={item.className} key={item.text}>
            {item.isExternal ? (
                <a
                    href={intl.formatMessage({ id: item.url })}
                    title={intl.formatMessage({ id: item.description })}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-link"
                >
                    <FormattedMessage id={item.text} />
                </a>
            ) : (
                <LinkLocalized
                    to={item.url}
                    view={item.view}
                    title={item.description}
                    className="footer-link"
                    {...(item.tracking || {})}
                >
                    <FormattedMessage id={item.text} />
                </LinkLocalized>
            )}
        </FooterListItem>
    ))
}

const renderLists = (intl, groupKey) =>
    listGroups[groupKey].map((list) => (
        <FooterList
            isExpanded={list.isExpanded}
            className={list.className}
            key={list.title}
        >
            {renderListTitle(list)}
            {renderListLinks(intl, list)}
        </FooterList>
    ))

const renderListGroups = (intl) =>
    Object.keys(listGroups).map((groupKey, index) => (
        <FooterListGroup
            key={groupKey}
            className={
                index === 0
                    ? 'footer-groups-item__first'
                    : 'footer-groups-item__others'
            }
        >
            {renderLists(intl, groupKey)}
        </FooterListGroup>
    ))

export const PageFooter = ({ intl, className, hasFloatingCta }) => {
    const isMaxSm = useMediaQuery(mediaMaxSm)

    return (
        <Footer
            className={cn(hasFloatingCta && style.hasFloatingCta, className)}
        >
            {renderLogo(intl)}
            <FooterSection>
                <LanguageSwitch />
            </FooterSection>
            <div className="footer-groups">
                <div className="content-wrapper footer-groups-content">
                    {renderListGroups(intl, isMaxSm)}
                </div>
            </div>
            {renderNote()}
        </Footer>
    )
}

PageFooter.propTypes = {
    className: PropTypes.string,
    intl: PropTypes.object.isRequired,
    hasFloatingCta: PropTypes.bool.isRequired,
}

PageFooter.defaultProps = {
    className: '',
}

export const mapStateToProps = ({ layout }) => ({
    hasFloatingCta: layout.hasFloatingCta,
})

export default connect(mapStateToProps)(injectIntl(PageFooter))
