import _ from 'lodash'
import { Tracker } from '@gymondo/frontend-core/utils'
import { COOKIE_CONSENT_KEYS } from '@gymondo/frontend-core/enums'
import { getConsentValue } from '@gymondo/frontend-core/components/cookie-consent/util'
import { OAUTH_ERROR_CODES } from '../../../../config/definitions'
import CRUD, { getUserLocale } from '../../../../api/generic/crud/crud'
import URL from '../../../../config/url'
import { getCurrentHost, PATHS } from '../../../../config/route'
import { createUser } from '../../../../api/service/registration/registration.service'
import { loginEmail } from '../../../../api/service/login/login.service'

export const _getAbortLink = (toMyAccount) =>
    toMyAccount ? `${getCurrentHost()}${PATHS.account}` : `${getCurrentHost()}/`

export const requestDataUpdate = async (channelId) =>
    CRUD.get(
        `${URL.secureStore}/request/dataupdate`,
        {
            params: {
                channelId,
                abortLink: _getAbortLink(true),
            },
        },
        true
    )

export const _buildCreateUserPayload = (payload) => ({
    username: payload.email,
    password: payload.password,
    referral: payload.referral || null,
    newsletter: payload.newsletter,
    locale: getUserLocale(),
    registeredVia: 'WEBAPP',
})

export const _buildLoginPayload = (payload) => ({
    username: payload.email,
    password: payload.password,
})

export const _handleError = (error) => {
    const reason = _.get(error, 'response.data.code')

    if (reason === OAUTH_ERROR_CODES.DUPLICATE_USER) {
        return true
    }

    if (reason === OAUTH_ERROR_CODES.INVALID_PASSWORD) {
        throw new Error('checkout-signup.password.error')
    }

    if (reason === OAUTH_ERROR_CODES.NOT_AUTHORIZED) {
        throw new Error('checkout-signup.credentials.error')
    } else {
        throw new Error(
            _.get(error, 'response.message') ||
                _.get(error, 'response.data.error_description') ||
                'error.generic'
        )
    }
}

export const trackUserCreated = (email) => {
    Tracker.push({
        event: Tracker.EVENTS.TRACKING,
        eventCategory: 'checkout',
        eventAction: 'user_created',
        email,
    })
}

export const signup = async (payload) => {
    await createUser(_buildCreateUserPayload(payload))
        .then(
            () =>
                getConsentValue(
                    COOKIE_CONSENT_KEYS.GYM_CONSENT_FIRST_PARTY_DATA_OPTIN
                ) && trackUserCreated(payload.email)
        )
        .catch((err) => _handleError(err))

    await loginEmail(_buildLoginPayload(payload), false).catch((err) =>
        _handleError(err)
    )
}
