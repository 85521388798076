import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Tracker } from '@gymondo/frontend-core/utils'

import { Button } from '@gymondo/frontend-core/components'
import { LinkLocalized } from '../..'
import {
    getLocalizedTrialInterval,
    getTrialTitle,
    getTrialTitleId,
} from '../../../util/trial-interval.util'

import style from './trial-cta-button.module.scss'

export const TrialCtaButton = ({ isExistingUser, isLoggedIn }) => {
    const intl = useIntl()

    const formattedMessageId = getTrialTitleId(intl)
    const trialInterval = getLocalizedTrialInterval(intl)

    return (
        !isExistingUser &&
        !isLoggedIn && (
            <LinkLocalized
                to="checkout"
                title={getTrialTitle(intl)}
                className="top-header__btn"
                data-evt={Tracker.EVENT_TYPES.CLICK}
                data-category="global"
                data-action="click_register_cta"
                data-label="register_header"
            >
                <Button className={style.free} size="small">
                    <FormattedMessage
                        id={formattedMessageId}
                        {...(trialInterval && { values: { trialInterval } })}
                    />
                </Button>
            </LinkLocalized>
        )
    )
}

export const mapStateToProps = ({ user }) => ({
    ...user,
})

TrialCtaButton.propTypes = {
    isExistingUser: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps)(TrialCtaButton)
