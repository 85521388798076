import jsCookie from 'js-cookie'
import moment from 'moment'
import { COOKIE_NAMES } from '@gymondo/frontend-core/enums'
import trialIntervals from '../i18n/.build/trial.json'

export const getTrialInterval = () => {
    const region = jsCookie.get(COOKIE_NAMES.USER_REGION)

    return (
        trialIntervals &&
        trialIntervals[region] &&
        trialIntervals[region].trialInterval
    )
}

export const getLocalizedTrialInterval = (
    intl,
    trialInterval = getTrialInterval(),
    withHyphen
) => {
    if (trialInterval) {
        const [value, interval] = trialInterval.split(' ')
        const unit = moment.normalizeUnits(interval)
        const hyphen = withHyphen ? '.hyphen' : ''

        return intl.formatMessage(
            { id: `duration.${unit}${hyphen}.dynamic` },
            { value }
        )
    }

    return null
}

export const getTrialTitleId = () => {
    const trialInterval = getTrialInterval()

    // return trialInterval ? 'cta.trialInterval.dynamic' : 'cta.noTrialInterval'
    return trialInterval ? 'cta.trialInterval.static' : 'cta.noTrialInterval'
}

export const getTrialTitle = intl => {
    const formattedMessageId = getTrialTitleId()
    const trialInterval = getTrialInterval()

    return intl.formatMessage({ id: formattedMessageId }, { trialInterval })
}
