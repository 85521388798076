import { LOCALES } from '@gymondo/frontend-core/enums'
import { getUserLocale } from '../../../../../api/generic/crud/crud'

const SHOP_VOUCHER_PRODUCT_LENGTH = '12'

const PRODUCT_TO_FAKE_DISCOUNT = {
    web_eur_202303_24m: 0.5,
    web_eur_hs_202305_24m: 0.5,
}

const PRODUCT_TO_SAVE_AMOUNT = {
    web_eur_202207_12m: '50%',
    web_gbp_202203_12m: '50%',
    web_usd_202203_12m: '50%',
}

export const getProductDuration = (product) => {
    return product.interval.split(' ')[0]
}

export const getSaveBannerAmount = (product) => {
    return PRODUCT_TO_SAVE_AMOUNT[product?.articleNumber]
}

export const getFakeDiscount = (product) => {
    return PRODUCT_TO_FAKE_DISCOUNT[product?.articleNumber]
}

export const showShopVoucherAdvantage = (product) => {
    const productDuration = getProductDuration(product)

    return (
        `${productDuration}` === SHOP_VOUCHER_PRODUCT_LENGTH &&
        getUserLocale() === LOCALES.DE_DE
    )
}

export const showShopVoucherDisclaimer = (products) => {
    return (
        Array.isArray(products) &&
        products.some((product) => showShopVoucherAdvantage(product))
    )
}
